import React from 'react';
import VestingForm from './VestingForm';

const Vesting = () => {
  return (
    <main className='buy-widget' >
      <VestingForm />
    </main >
  );
};

export default Vesting;
