import React from 'react';
import Vesting from './components/Vesting';

function App() {
  return (
    <div className='page buy'>
          <main>
                  <article>
                      <Vesting />
                  </article>
          </main>
    </div >
  );
}

export default App;
