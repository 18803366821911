import { Contract } from '@ethersproject/contracts';
import { useEthers } from '@usedapp/core';
import { useMemo } from 'react';

export const useContract = (address, ABI, withSigner) => {
  const { library, account } = useEthers();

  return useMemo(
    () =>
      !!address && !!ABI && !!library
        ? new Contract(address, ABI, withSigner ? library.getSigner(account).connectUnchecked() : library)
        : null,
    [ address, ABI, withSigner, library, account ],
  );
};