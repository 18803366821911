import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Link,
  Tooltip,
  List,
  ListItem,
} from '@mui/material';
import { formatEther } from '@ethersproject/units';
import {
  getExplorerTransactionLink,
  shortenAddress,
  useEtherBalance,
  useEthers,
  useLookupAddress,
  useTransactions,
} from '@usedapp/core';
import { GrTransaction } from 'react-icons/gr';
import { shortenHash } from '../utils';
import Identicon from './shared/Identicon';

export default function AccountModal() {
  const ens = useLookupAddress();
  const { account, chainId } = useEthers();
  const { transactions } = useTransactions();
  const etherBalance = useEtherBalance(account);
  const [isOpen, setIsOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleCopyAddress = async () => {
    await navigator.clipboard.writeText(account);
    setIsTooltipOpen(true);
    setTimeout(() => setIsTooltipOpen(false), 1000);
  };

  return (
      <div className="account">
        <Button
            onClick={() => setIsOpen(true)}
            variant="contained"
            size="large"
            sx={{
              marginTop: '1rem',
              marginBottom: '1rem',
            }}
        >
          <Identicon account={account} diameter={20} />
          &nbsp;&nbsp;
          {ens ?? shortenAddress(account)}
        </Button>
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="md" fullWidth>
          <DialogTitle>Account</DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '16px' }}>
              <Identicon account={account} diameter={80} />
              {etherBalance && (
                  <Typography>
                    Balance: {formatEther(etherBalance)} BNB
                  </Typography>
              )}
              {account && (
                  <Typography>Address: {shortenAddress(account)}</Typography>
              )}
              <Tooltip
                  title="Copied Address"
                  open={isTooltipOpen}
                  onClose={() => setIsTooltipOpen(false)}
                  arrow
                  disableHoverListener
              >
                <Link
                    className="button"
                    onClick={handleCopyAddress}
                    variant="body2"
                    style={{ cursor: 'pointer' }}
                >
                  Copy Address
                </Link>
              </Tooltip>
              <Link
                  href={`https://ropsten.etherscan.io/address/${account}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="body2"
                  style={{ marginTop: '16px' }}
              >
                View on Explorer
              </Link>
            </div>
            <List>
              {transactions.slice(0, 10).map((transaction, index) => (
                  <ListItem key={index}>
                    <Link
                        href={getExplorerTransactionLink(
                            transaction.transaction.hash,
                            chainId
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="body2"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <GrTransaction />
                      <Typography>
                        {`${transaction.transactionName}: ${shortenHash(
                            transaction.transaction.hash
                        )}`}
                      </Typography>
                    </Link>
                  </ListItem>
              ))}
            </List>
          </DialogContent>
        </Dialog>
      </div>
  );
}
