import { DAppProvider } from '@usedapp/core/dist/esm/src/providers/DAppProvider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './App.css.old';
import { Mumbai } from '@usedapp/core';
import { BSC } from '@usedapp/core';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/600.css';
import '@fontsource/rubik/700.css';


const config = {
  readOnlyChainId: BSC.chainId,
  readOnlyUrls: {
    [ BSC.chainId ]:
      'https://bsc-dataseed.binance.org/',
  },
  networks: [ BSC]
};
// const config = {
//     readOnlyChainId: Mumbai.chainId,
//     readOnlyUrls: {
//         [ Mumbai.chainId ]:
//             'https://matic.getblock.io/8ace5751-bb86-4de5-96bc-5eeddd1ca0e5/testnet/',
//     },
//     networks: [ Mumbai]
// };


const theme = createTheme({
    typography: {
        fontFamily: `'Rubik', sans-serif`,
    },
});

ReactDOM.render(
  <StrictMode >
      <DAppProvider config={ config } >
          <App />
      </DAppProvider >
  </StrictMode >,
  document.getElementById('root'),
);

export default theme;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
