import React, { useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEthers } from '@usedapp/core';
import { FaWallet } from 'react-icons/fa';
import AccountModal from './AccountModal';
import Toast from './shared/Toast';
import { constants } from '../utils';
import {OpenInNew} from "@mui/icons-material";

const networks = {
    bsc: {
      chainId: `0x${Number(56).toString(16)}`,
      chainName: 'BSC Mainnet',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: ['https://bsc-dataseed1.binance.org'],
      blockExplorerUrls: ['https://bscscan.com/'],
    },
    // mumbai: {
    //     chainId: `0x${Number(constants.CHAIN_ID).toString(16)}`,
    //     chainName: 'Mumbai',
    //     nativeCurrency: {
    //         name: 'MATIC',
    //         symbol: 'MATIC',
    //         decimals: 18,
    //     },
    //     rpcUrls: ['https://matic.getblock.io/8ace5751-bb86-4de5-96bc-5eeddd1ca0e5/testnet/'],
    //     blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
    // },
};

export default function AccountButton() {
    const [loading, setLoading] = useState(false);
    const { account, deactivate, activateBrowserWallet, error, chainId } =
        useEthers();

    const renders = useRef(0);

    useEffect(() => {
        if (error && error.message.includes('Already processing eth_requestAccounts')) {
            if (error.message.includes('Already processing eth_requestAccounts')) {
                Toast('Info', 'Open metamask popup and connect.', 'info');
            } else {
                Toast('Error', error.message, 'error');
            }
        }

        if (renders.current > 1 && account) {
            Toast('Wallet Connected', null, 'success');
        }

        if (renders.current > 1 && !account && !error) {
            Toast('Wallet Disconnected', null, 'error');
        }
    }, [account, error]);

    useEffect(() => {
        renders.current = renders.current + 1;

        if (chainId && chainId !== constants.CHAIN_ID) {
            console.log('chain:', chainId);
            Toast('Wrong network', null, 'error');
            handleNetworkSwitch('mumbai').then();
        }

        if (renders.current > 3 && account && chainId) {
            Toast('Network Changed', null, 'info');
        }
    }, [chainId]);

    const activate = () => {
        setLoading(true);
        setTimeout(() => {
            activateBrowserWallet();
            setLoading(false);
        }, 700);
    };

    const handleDisconnect = () => {
        setLoading(true);
        setTimeout(() => {
            deactivate();
            setLoading(false);
        }, 700);
    };

    const handleNetworkSwitch = async (networkName) => {
        await changeNetwork({ networkName });
    };

    const changeNetwork = async ({ networkName }) => {
        try {
            setTimeout(async () => {
                if (!window.ethereum) {
                    throw new Error('No crypto wallet found');
                }

                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            ...networks[networkName],
                        },
                    ],
                });

                activate();
            }, 2000);
        } catch (err) {
            Toast('Error', null, 'error');
        }
    };

    function renderWalletButton() {
        if (!window.ethereum) {
            return (
                <Button
                    component={Link}
                    href="https://metamask.io/download/"
                    target="_blank"
                    rel="noopener noreferrer"
                    startIcon={<FaWallet />}
                    variant="contained"
                    size="large"
                    sx={{
                        marginTop: '1rem',
                        borderRadius: '14px',
                        background: 'linear-gradient(260deg, rgb(81, 154, 250), rgb(80, 255, 240))',
                        '&:hover': {
                            background: 'linear-gradient(260deg, rgb(0, 0, 0), rgb(0, 0, 0))',
                            boxShadow: 'rgb(119 0 255) -1px 2px 2px 2px, rgb(243 255 80) 2px -3px 2px 2px',
                            color: 'white',
                        },
                    }}
                >
                    Install Metamask <OpenInNew />
                </Button>
            );
        }

        if (account) {
            return (
                <ButtonGroup
                  className="account-buttons-group"
                  variant="contained"
                  size="large"
                  sx={{
                    marginBottom: '1rem',
                    display: 'flex',
                    alignItems: 'stretch',
                    flexDirection: 'column',
                    '@media (min-width: 576px)': {
                      flexDirection: 'row',
                    }
                  }}>
                    <AccountModal />
                    <Button
                        variant="contained"
                        size="large"
                        startIcon={<FaWallet />}
                        onClick={handleDisconnect}
                        disabled={loading}
                        sx={{
                            borderTopLeftRadius: '14px',
                            borderTopRightRadius: '5px',
                            borderBottomLeftRadius: '5px',
                            borderBottomRightRadius: '14px',
                            background: 'linear-gradient(260deg, rgb(81, 154, 250), rgb(80, 255, 240))',
                            '&:hover': {
                                background: 'linear-gradient(260deg, rgb(0, 0, 0), rgb(0, 0, 0))',
                                boxShadow: 'rgb(119 0 255) -1px 2px 2px 2px, rgb(243 255 80) 2px -3px 2px 2px',
                                color: 'white',
                            },
                        }}
                    >
                        Disconnect
                    </Button>
                </ButtonGroup>
            );
        }

        return (
            <Button
                variant="contained"
                size="large"
                startIcon={<FaWallet />}
                onClick={activate}
                disabled={loading}
                sx={{
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    borderTopLeftRadius: '14px',
                    borderTopRightRadius: '5px',
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '14px',
                    background: 'linear-gradient(260deg, rgb(81, 154, 250), rgb(80, 255, 240))',
                    '&:hover': {
                        background: 'linear-gradient(260deg, rgb(0, 0, 0), rgb(0, 0, 0))',
                        boxShadow: 'rgb(119 0 255) -1px 2px 2px 2px, rgb(243 255 80) 2px -3px 2px 2px',
                        color: 'white',
                    },
                }}
            >
                Connect
            </Button>
        );
    }

    return <Typography>{renderWalletButton()}</Typography>;
}