import {BigNumber} from "ethers";

export const shortenHash = (hashStr) => {
  return hashStr.slice(0, 8) + '...' + hashStr.slice(-20);
};

export const constants = {
  GGTOKEN_ADDRESS: '0x1f7e8fe01aeba6fdaea85161746f4d53dc9bda4f',
  CHAIN_ID: 56
};

export const formatBigNumberToDate=(bigNumberHex)=> {
  const timeInMilliseconds = parseInt(BigNumber.from(bigNumberHex).toString()) * 1000;
  return new Date(timeInMilliseconds);
}

export const addDaysToDate=(date, days) =>{
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}