
import React from 'react';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

export default function Toast(title, description, status) {
  return (
      <Snackbar
          open={true}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity={status} sx={{ borderRadius: '14px' }}>
          <strong>{title}</strong>
          <br />
          {description}
        </Alert>
      </Snackbar>
  );
}