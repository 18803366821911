import React, {useEffect, useState} from 'react';
import {Button, Typography} from '@material-ui/core';
import {formatEther, parseUnits} from '@ethersproject/units';
import {useEtherBalance, useEthers, useTokenBalance} from '@usedapp/core';
import {utils} from 'ethers';
import {useContract} from '../hooks/useContract';
import {constants, shortenHash, formatBigNumberToDate, addDaysToDate} from '../utils';
import GGVesting from './../assets/abi/GGVesting.json';
import ERC20Json from './../assets/abi/ERC20Interface.json';
import AccountButton from './AccountButton';
import {
    Alert,
    Card,
    CardActions,
    CardContent,
    Container, Grid,
    Paper,
    Snackbar,
    TextField,
} from '@mui/material';
import {PulseLoader} from "react-spinners";

const ERC20_ABI = new utils.Interface(ERC20Json.abi);
const GGTOKEN_ADDRESS = constants.GGTOKEN_ADDRESS;
const VESTING_CONTRACT_ABI = new utils.Interface(GGVesting.abi);

const VestingForm = () => {
    const [tokenBalance, setTokenBalance] = useState('');
    const [nativeBalance, setNativeBalance] = useState('');
    const [vestError, setVestError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {account, chainId} = useEthers();

    const [isApplied, setIsApplied] = useState(false);
    const [hasVested, setHasVested] = useState(false);
    const [isWhitelisted, setIsWhitelisted] = useState(false);
    const [vestAmount, setVestAmount] = useState('');
    const [disable, setDisable] = useState(chainId !== constants.CHAIN_ID);

    const [toastOpen, setToastOpen] = useState(false);
    const [toastTitle, setToastTitle] = useState('');
    const [toastDescription, setToastDescription] = useState('');
    const [toastStatus, setToastStatus] = useState('');

    const [vestId, setVestId] = useState('');
    const [vestingAmount, setVestingAmount] = useState('');
    const [vestingStartTime, setVestingStartTime] = useState('');
    const [vestingEndTime, setVestingEndTime] = useState('');
    const [vestingCliffPeriod, setVestingCliffPeriod] = useState('');
    const [vestingClaimedAmount, setVestingClaimedAmount] = useState('');
    const [vestingClaimableAmount, setVestingClaimableAmount] = useState('');

    const ggTokenContract = useContract(GGTOKEN_ADDRESS, ERC20_ABI, true);
    const vestingContract = useContract(process.env.REACT_APP_VESTING_CONTRACT_ADDRESS, VESTING_CONTRACT_ABI, true);
    const ggTokenBalance = useTokenBalance(GGTOKEN_ADDRESS, account);
    const nBalance = useEtherBalance(account);

    useEffect(() => {
        console.log('chain id:', chainId);
        console.log(constants.CHAIN_ID);
        setDisable(chainId !== constants.CHAIN_ID);
    }, [chainId]);

    useEffect(() => {
        if (ggTokenBalance && nBalance) {
            setTokenBalance(formatEther(ggTokenBalance));
            setNativeBalance(formatEther(nBalance));
        }
    },);

    useEffect(() => {
        async function checkAddressApplied() {
            try {
                const applied = await vestingContract.isAddressAppliedForVesting(account);
                console.log('is address applied:', applied);
                setIsApplied(applied);
            } catch (error) {
                console.log('Error checking address applied:', error);
            }
        }

        async function checkIfUserHasVested() {
            try {
                const vested = await vestingContract.hasAddressVested(account);
                console.log('has user vested:', hasVested);
                setHasVested(vested);
            } catch (error) {
                console.log('Error checking if user has vested:', error);
            }
        }

        async function checkIfAddressWhitelisted() {
            try {
                const whitelisted = await vestingContract.isAddressAllowedToVest(account);
                console.log('is address whitelisted:', whitelisted);
                setIsWhitelisted(whitelisted);
            } catch (error) {
                console.log('Error checking address whitelisted:', error);
            }
        }

        async function getUserVest() {
            if (hasVested) {
                try {
                    const vest = await vestingContract.getAccountVest(account);
                    const claimableAmount = await vestingContract.calculateClaimableAmount(vest.id);
                    setVestId(vest.id.toString());
                    setVestingAmount(formatEther(vest.amount));
                    setVestingStartTime(formatBigNumberToDate(vest.startTime).toDateString());
                    setVestingEndTime(vest.vestingPeriod.toString() + ' days');
                    setVestingCliffPeriod(vest.cliffPeriod.toString() + ' days');
                    setVestingClaimedAmount(formatEther(vest.claimedAmount));
                    setVestingClaimableAmount(formatEther(claimableAmount));

                    console.log('vest:', vest);
                } catch (error) {
                    console.log('Error getting user vest:', error);
                }

            }
        }

        checkAddressApplied();
        checkIfAddressWhitelisted();
        checkIfUserHasVested();
        getUserVest();
    }, [isApplied, hasVested, isWhitelisted, account, isLoading]);

    const handleAmountChange = (event) => {
        if (event.target.value > parseFloat(tokenBalance)) {
            setVestAmount(event.target.value);
            setVestError('Input amount is greater than your balance');
        } else {
            setVestAmount(event.target.value);
            setVestError('');
        }
    };

    const handleVestTokens = async (e) => {
        e.preventDefault();
        try {

            setIsLoading(true);
            const approveTx = await ggTokenContract.approve(
                process.env.REACT_APP_VESTING_CONTRACT_ADDRESS,
                parseUnits(vestAmount, 'ether')
            );
            console.log(approveTx);
            const approveReceipt = await approveTx.wait();
            console.log(approveReceipt);
            setToastOpen(true);
            setToastTitle('Approved');
            setToastDescription(shortenHash(approveReceipt.transactionHash));
            setToastStatus('success');
            // Toast('Approved', shortenHash(approveReceipt.transactionHash), 'success');
            console.log('amount', parseUnits(vestAmount, 'ether').toString());
            const buyTx = await vestingContract.vest(parseUnits(vestAmount, 'ether'));
            const buyReceipt = await buyTx.wait();
            setToastOpen(true);
            setToastTitle('Vesting Tokens');
            setToastDescription(shortenHash(buyReceipt.transactionHash));
            setToastStatus('success');
            setIsLoading(false);

        } catch (err) {
            setIsLoading(false);
            setToastOpen(true);
            setToastTitle('Exception');
            setToastDescription(err.data ? err.data.message : err.message);
            setToastStatus('error');
        }
    };

    const handleApplyForVesting = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const applyTx = await vestingContract.applyForVesting();
            const applyReceipt = await applyTx.wait();
            setToastOpen(true);
            setToastTitle('Applied For Vesting');
            setToastDescription(shortenHash(applyReceipt.transactionHash));
            setToastStatus('success');
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setToastOpen(true);
            setToastTitle('Exception');
            setToastDescription(err.data ? err.data.message : err.message);
            setToastStatus('error');
        }
    }

    const handleGetAvailableFunds = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const getAvailableFundsTx = await vestingContract.claimAvailableVestingAmount(vestId);
            const getAvailableFundsReceipt = await getAvailableFundsTx.wait();
            setToastOpen(true);
            setToastTitle('Available Funds');
            setToastDescription(shortenHash(getAvailableFundsReceipt.transactionHash));
            setToastStatus('success');
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setToastOpen(true);
            setToastTitle('Exception');
            setToastDescription(err.data ? err.data.message : err.message);
            setToastStatus('error');
        }
    }

    return (
      <Container
        maxWidth="lg"
        sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            height: '100vh',
        }}
      >
          <Card sx={{ minWidth: 275, padding: '16px' }}>
              <CardContent>
                  <Snackbar
                    open={toastOpen}
                    autoHideDuration={5000}
                    onClose={() => setToastOpen(false)}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                  >
                      <Alert severity={toastStatus} sx={{borderRadius: '14px'}}>
                          <strong>{toastTitle}</strong>
                          <br/>
                          {toastDescription}
                      </Alert>
                  </Snackbar>

                  {/*<div className="container">*/}
                      <CardActions sx={{ justifyContent: 'center'}}>
                          <AccountButton/>
                      </CardActions>
                  {/*</div>*/}

                  {!account && (
                    <div>
                        <br/>
                        <br/>
                        <Typography> WIDGET FOR VESTING YOUR GG TOKENS</Typography>
                        <br/>
                    </div>
                  )}

                  {account && !isApplied && (
                    <Grid container alignItems="center" sx={{ marginBottom: '8px', flexFlow: 'column', textAlign: 'center' }}>
                       <Grid item sx={{ paddingBottom: '10px' }}>
                           <Typography> PLEASE APPLY FOR VESTING YOUR GG TOKENS</Typography>
                       </Grid>
                       <Grid item>
                           <Button
                             // Apply For Vesting button
                             variant="contained"
                             color="primary"
                             onClick={handleApplyForVesting}
                             disabled={vestError || disable || !account}
                           >
                               Apply For Vesting
                           </Button>
                       </Grid>
                    </Grid>
                  )}

                  {account && isApplied && hasVested && (
                    <div>
                        <Grid container component={Paper} sx={{ padding: '16px' }}>
                            <Grid container item alignItems="center" justifyContent="space-between" sx={{ marginBottom: '8px', flexFlow: 'row wrap', '@media (max-width: 576px)': { flexFlow: 'column' } }}>
                                <Typography className="bold-typography" sx={{ fontSize: '1rem' }}>
                                    Native Balance
                                </Typography>
                                <Typography>
                                    {nativeBalance}
                                </Typography>
                            </Grid>
                            <Grid container item alignItems="center" justifyContent="space-between" sx={{ marginBottom: '8px', flexFlow: 'row wrap', '@media (max-width: 576px)': { flexFlow: 'column' } }}>
                                <Typography className="bold-typography" sx={{ fontSize: '1rem' }}>
                                    GG Token Balance
                                </Typography>
                                <Typography>
                                    {tokenBalance}
                                </Typography>
                            </Grid>
                            <Grid container item alignItems="center" justifyContent="space-between" sx={{ marginBottom: '8px', flexFlow: 'row wrap', '@media (max-width: 576px)': { flexFlow: 'column' } }}>
                                <Typography className="bold-typography" sx={{ fontSize: '1rem' }}>
                                    Vesting Amount
                                </Typography>
                                <Typography>
                                    {vestingAmount}
                                </Typography>
                            </Grid>
                            <Grid container item alignItems="center" justifyContent="space-between" sx={{ marginBottom: '8px', flexFlow: 'row wrap', '@media (max-width: 576px)': { flexFlow: 'column' } }}>
                                <Typography className="bold-typography" sx={{ fontSize: '1rem' }}>
                                    Vesting Start Time
                                </Typography>
                                <Typography>
                                    {vestingStartTime}
                                </Typography>
                            </Grid>
                            <Grid container item alignItems="center" justifyContent="space-between" sx={{ marginBottom: '8px', flexFlow: 'row wrap', '@media (max-width: 576px)': { flexFlow: 'column' } }}>
                                <Typography className="bold-typography" sx={{ fontSize: '1rem' }}>
                                    Vesting Period
                                </Typography>
                                <Typography>
                                    {vestingEndTime}
                                </Typography>
                            </Grid>
                            <Grid container item alignItems="center" justifyContent="space-between" sx={{ marginBottom: '8px', flexFlow: 'row wrap', '@media (max-width: 576px)': { flexFlow: 'column' } }}>
                                <Typography className="bold-typography" sx={{ fontSize: '1rem' }}>
                                    Cliff Period
                                </Typography>
                                <Typography>
                                    {vestingCliffPeriod}
                                </Typography>
                            </Grid>
                            <Grid container item alignItems="center" justifyContent="space-between" sx={{ marginBottom: '8px', flexFlow: 'row wrap', '@media (max-width: 576px)': { flexFlow: 'column' } }}>
                                <Typography className="bold-typography" sx={{ fontSize: '1rem' }}>
                                    Claimable Amount
                                </Typography>
                                <Typography>
                                    {vestingClaimableAmount}
                                </Typography>
                            </Grid>
                            <Grid container item alignItems="center" justifyContent="space-between" sx={{ marginBottom: '8px', flexFlow: 'row wrap', '@media (max-width: 576px)': { flexFlow: 'column' } }}>
                                <Typography className="bold-typography" sx={{ fontSize: '1rem' }}>
                                    Claimed
                                </Typography>
                                <Typography>
                                    {vestingClaimedAmount}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                  )}

                  {account && isApplied && !hasVested && (
                    <>
                            <Grid container component={Paper} sx={{ padding: '16px' }}>
                                <Grid container item alignItems="center" justifyContent="space-between" sx={{ marginBottom: '8px', flexFlow: 'row wrap', '@media (max-width: 576px)': { flexFlow: 'column' } }}>
                                    <Grid item alignItems="center" sx={{ marginBottom: '8px', flexFlow: 'column', textAlign: 'center' }}>
                                        <Typography className="bold-typography" sx={{ fontSize: '1rem' }}>
                                            Native Balance
                                        </Typography>
                                        <Typography>
                                            {nativeBalance}
                                        </Typography>
                                    </Grid>
                                    <Grid item alignItems="center" sx={{ marginBottom: '8px', flexFlow: 'column', textAlign: 'center' }}>
                                        <Typography className="bold-typography" sx={{ fontSize: '1rem' }}>
                                            GG Token Balance
                                        </Typography>
                                        <Typography>
                                            {tokenBalance}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item alignItems="center" justifyContent="space-between" sx={{ marginBottom: '8px', flexFlow: 'column', textAlign: 'center' }}>
                                    <TextField
                                      type="number"
                                      placeholder="Enter amount of tokens"
                                      value={vestAmount}
                                      onChange={handleAmountChange}
                                      bordered
                                    />
                                    {vestError !== '' && (
                                      <div className="alert-red">{vestError}</div>
                                    )}
                                    {vestError === '' && (
                                      <div className="alert-red">&nbsp;</div>)}
                                </Grid>
                                <Grid container item alignItems="center" justifyContent="space-between" sx={{ marginBottom: '8px', flexFlow: 'column' }}>
                                    <Button
                                      // Vest button
                                      variant="contained"
                                      color="primary"
                                      onClick={handleVestTokens}
                                      disabled={vestError || disable || !account}
                                    >
                                        Vest
                                    </Button>
                                </Grid>
                            </Grid>
                    </>
                  )}

                  {isLoading &&
                    <div className="loading-overlay">
                        <PulseLoader size={35} color="#3696e5" margin={80}/>
                    </div>
                  }

                  <style jsx>{
                      `.bold-typography {
                          font-weight: bold;
                      }
                          .container {
                  height: 50px;
                  position: relative;
                  text-align: center;
                  margin: auto;
                }

                .vesting-info {
                  display: flex;
                  justify-content: center;
                }

                .vesting-row {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 1rem;
                  width: 100%;
                }

                .vesting-row > div {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  text-align: center;
                }

                .vesting-row > div > :not(:last-child) {
                  margin-bottom: 0.5rem;
                }

                .card {
                  background-color: #ffffff;
                  border-radius: 0.5rem;
                  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
                  padding: 1rem;
                  border-style: solid;
                  max-width: 35%;
                  text-align: center;
                  margin: auto;
                  position: relative;
                }

                .loading-overlay {
                  top: 0;
                  left: 0;
                  height: 100%;
                  width: 100%;
                  text-align: center;
                  position: absolute;
                  background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background */
                }
                `
                  }

                  </style>
              </CardContent>
              <CardActions  sx={{ justifyContent: 'center'}}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGetAvailableFunds}
                    disabled={vestError || disable || !account || (vestingClaimableAmount <= 0 || vestingClaimableAmount === '')}
                  >
                      Claim Available Funds
                  </Button>
              </CardActions>
          </Card>
      </Container>
    );
};

export default VestingForm;